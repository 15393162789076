import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Link,
  //    NavLink, useHistory, useLocation
} from "react-router-dom";
//import Swal from "sweetalert2";
import "../css/landing.css";
import { Collapse } from "react-bootstrap";
//import Rightcontainer from "../Components/Rightcontainer";
import Downloadbutton from "../Components/Downloadbutton";
import Header from "../Components/Header";

export default function Landing() {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  var baseUrl;
  if (nodeMode === "development") {
    baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }
  const [open, setOpen] = useState(false);
  const [userAllData, setUserAllData] = useState();
  const [WebSitesettings, setWebsiteSettings] = useState("");
  const fetchData = async () => {
    const response = await fetch(baseUrl + "settings/data");
    const data = await response.json();
    return setWebsiteSettings(data);
  };
  
  

  const role = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    await axios
      .get(baseUrl + `me`, { headers })
      .then((res) => {
        setUserAllData(res.data);
      })
      .catch((e) => {
        if (e.response.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          //window.location.href = "/login";
          //window.location.reload()
          //history.push("/login")
        }
      });
  };

  useEffect(() => {
    let access_token = localStorage.getItem("token");
    access_token = localStorage.getItem("token");
    if (!access_token) {
      //window.location.reload()
    }
    role();
    fetchData();
  }, []);
  
  
  
  
  
  
  

  return (
    <>
      <Header user={userAllData} />
    
      <div className="leftContainer">
     
        <div className="main-area" style={{ paddingTop: "60px" }}>
        
        {/*<div className="referBox"> 
            Commission: 5% • Refferal: 2% For All Games
        </div>*/}
        
        {userAllData && userAllData.verified === 'unverified' && 
        
            <div className="container mt-3 pl-3 pr-3">
                <div className="collapseCard-body" 
                style={{height: "64px", opacity: 1, transition: "height 0.3s ease 0s, opacity 0.3s ease 0s",}}>
                        <span className="blink text-danger float-left font-weight-bold">KYC Pending !</span> &emsp; &nbsp;
                        <span className="float-right">
                        <Link to={(userAllData && userAllData.verified === `unverified`) ? `/Kyc2` : `/Profile` } className="btn btn-danger text-light btn-sm">
                            COMPLETE NOW
                        </Link>
                        </span>
                </div>
            </div>}
            
        {userAllData && userAllData.verified === 'pending' && 
        
            <div className="container mt-3 pl-3 pr-3">
                <div className="collapseCard-body" 
                style={{height: "64px", opacity: 1, transition: "height 0.3s ease 0s, opacity 0.3s ease 0s",}}>
                        <span className="blink text-danger float-left">Your KYC is Under Processing !</span>
                        
                </div>
            </div>}
        
        {/*Refer and earn section delete this after apply*/}
        {/*<div className="collapseCard-container">
     
            <div className="collapseCard">
              <div style={{ textDecoration: "none" }}>
                <div className="collapseCard-body" 
                style={{height: "64px", opacity: 1, transition: "height 0.3s ease 0s, opacity 0.3s ease 0s",}}>
                  
                <a href="https://www.youtube.com/watch?v=yc8HqnpVNVs" target="_blank" className="btn btn-primary text-light btn-sm mr-1">
                    Add Cash video
                </a>
                
                <a href="https://youtu.be/rPPODIz9PRo" target="_blank" className="btn btn-primary text-light btn-sm mr-1">
                    How to Play?
                </a>                
                
                <Link to={"/"} className="btn btn-success text-light btn-sm mr-1">
                    KYC video
                </Link>
                </div>
              </div>

              <div
                className="collapseCard-header"
                style={{ left: "22px", transition: "left 0.3s ease 0s"}}
              >
                <picture>
                  <img
                    height="10px"
                    width="14px"
                    src="/Images/global-ytPlayIcon.png"
                    alt=""
                  />
                </picture>
                <div className="collapseCard-title ml-1 mt-1">यहाँ कैसे खेलें वीडियो की मदद से सीखें</div>
              </div>
            </div>
        </div>*/}
        
        
       {/* <div className="collapseCard-body-n justify m-3 p-3">
                <div className=" collapseCard-text alrt_msg_div pt-0 pb-1">
                    <span className={`text-danger`}>      
		            अगर आपने कोई पेमेंट किया है और वो ऐड नहीं हुआ है तो Transtion History पर जाये और check status पर क्लिक करे बैलेंस ऐड हो जायेगा 
                    </span>
                </div>
            </div>*/}
        
        {/*<div className="collapseCard-container p-3">
     
            <div className="collapseCard">
              <Link to={"/refer"} style={{ textDecoration: "none" }}>
                <div className="collapseCard-body" 
                style={{height: "64px", opacity: 1, transition: "height 0.3s ease 0s, opacity 0.3s ease 0s",}}>
                  <div className="collapseCard-text text-dark">
                    {"Referral: 2% • Commission: 5% For All Games"}
                    <span className=" text-danger">
                      {" "}
                    {WebSitesettings?.msg}{""}
                    </span>
                  </div>
                </div>
              </Link>

              <div
                className="collapseCard-header"
                style={{ left: "22px", transition: "left 0.3s ease 0s"}}
              >
                <picture>
                  <img
                    height="10px"
                    width="14px"
                    src="/Images/LandingPage_img/global-rupeeIcon.png"
                    alt=""
                  />
                </picture>
                <div className="collapseCard-title ml-1 mt-1">Refer & Earn</div>
              </div>
            </div>
          </div>*/}
            
        {WebSitesettings && WebSitesettings.MessageLanding.length >1 && 
            <div className="gameCard border border-light m-3 pt-2 px-3 py-1" >
                    <h6 className="d-none pt-2  text-danger d-block text-center">
                    {WebSitesettings.MessageLanding}
                    </h6>
                </div>
           }
            {/*<div className="gameCard pt-2 px-3 py-1" >
                    <h5 className="d-none pt-2  text-danger d-block text-center">
                    ◉ बैंक सर्वर की वजह से डिपोजिट मै समस्या आ रही है जो अगले 24 से 48 घंटे मै सॉल्व हो जायेगी।
                    </h5>
                </div>
                
            <div className="gameCard pt-2 px-3 py-1" >
                    
                    <h5 className="d-none pt-2  text-success d-block text-center">
                    <a className="text-success" href="https://api.whatsapp.com/send?phone=+918852809296&text=Hello, I need your help">
                        ◉ किसी भी समस्या के लिए 8852809296 पर whatsapp मैसेज और कॉल करें।
                    </a>
                    </h5>
                    
                </div>*/}
                
                
  
          <section className="games-section p-3">
          <div className="d-flex align-items-center mt-3 font-weight-bold games-section-title">Our Games</div>
           <div className="gameBox">
               
               <div className="games-section-headline mt-2 mb-1">
                  <img
                src={process.env.PUBLIC_URL + "/Images/Homepage/battleIcon.png"}
                alt=""
                width="20px"
              /> is for Battles and
                  <img
                    className="ml-1"
                    src={process.env.PUBLIC_URL +"/Images/global-blue-tournamentIcon.png"}
                     alt=""
                width="20px"
              />{" "}
                  is for Tournaments. <span>Know more here.</span>
                </div>

               
               {/* <div className="youtube"><a href="https://youtu.be/rPPODIz9PRo" target="_blank" className="text-light"><picture>
                  <img
                    height="10px"
                    width="14px"
                    src="/Images/global-ytPlayIcon.png"
                    alt=""
                  />
                </picture>&nbsp; How to play?
                </a></div>
                <div className="youtube"><a href="https://www.youtube.com/watch?v=yc8HqnpVNVs" target="_blank" className="text-light">
                    ADD CASH
                </a></div>
                <div className="youtube"><a href="/" target="_blank" className="text-light">
                    KYC Video
                </a></div>*/}
                
           </div>
            
            {/*<div className="d-flex align-items-center games-section-title">
              Tournaments
            </div>*/}
            
            <div className="games-section-headline mt-2 mb-1">
              <div className="games-window ">
                <Link
                  className="gameCard-container mb-2"
                  to={`/Homepage/Ludo%20Classics%20Manual`}
                >
                  <span className="d-none blink text-danger d-block text-right">
                    ◉ LIVE
                  </span>
                  <picture className="gameCard-image">
                    <img
                      width="100%"
                      src={
                        process.env.PUBLIC_URL +
                        "/Images/LandingPage_img/ManualCode.png"
                      }
                      alt=""
                    />
                  </picture>
                  <div className="gameCard-title">
                    <span className="d-none text-dark font-weight-bold d-block text-left">
                      Ludo Classic
                    </span>
                  </div>
                  
                </Link>
                
                
                {/*<Link
                  className="gameCard-container mb-2"
                   to={`/Homepage/Ludo%20Classics%20Auto`}
                >
                  <span className="d-none blink text-success d-block text-right">
                    ◉ Live
                  </span>
                  <picture className="gameCard-image">
                    <img
                      width="100%"
                      src={
                        process.env.PUBLIC_URL +
                        "/Images/LandingPage_img/AutoCode.png"
                      }
                      alt=""
                    />
                  </picture>
                   <div className="gameCard-title">
                    <span className="d-none blink text-success d-block text-right">
                      ◉ LUDO CLASSICS
                    </span>
                  </div>
                  <div className="goverlay">
                    <div className="text">Comming Soon</div>
                  </div>
                </Link>*/}

                {/*<Link className="gameCard-container mb-2" to={`/Homepage/Ludo%20Popular`}> 
                
                  <span className="d-none blink text-success d-block text-right">
                    ◉ LIVE
                  </span>
                  <picture className="gameCard-image">
                    <img
                      width="100%"
                      src={
                        process.env.PUBLIC_URL +
                        "/Images/LandingPage_img/popular.png"
                      }
                      alt=""
                    />
                  </picture>
                  <div className="gameCard-title">
                    <span className="d-none blink text-success  d-block text-right">
                      ◉ LUDO POPULAR
                    </span>
                  </div>
                  <div className="goverlay">
                    <div className="text">Comming Soon</div>
                  </div>
                </Link>*/}
                
                
                <Link className="gameCard-container mb-2" to={`/Homepage/Snakes`}> 
                
                  <span className="d-none blink text-danger d-block text-right">
                    ◉ LIVE
                  </span>
                  <picture className="gameCard-image">
                    <img
                      width="100%"
                      src={
                        process.env.PUBLIC_URL +
                        "/Images/LandingPage_img/snake.jpeg"
                      }
                      alt=""
                    />
                  </picture>
                  <div className="gameCard-title">
                    <span className="d-none text-dark font-weight-bold d-block text-right">
                      SNAKES
                    </span>
                  </div>
                  
                </Link>

                {/* <Link className="gameCard-container" to={`/Homepage/Ludo%201%20Goti`}> */}
                {/* <Link className="gameCard-container" to={``}>
                                        <span className="d-none blink text-danger d-block text-right">◉ LIVE</span>
                                        <picture className="gameCard-image">
                                            <img width="100%" src={process.env.PUBLIC_URL + '/Images/LandingPage_img/thirdLudo.webp'} alt="" />
                                        </picture>
                                       
                    <div className="gameCard-title">
                                            <span className="d-none blink text-success d-block text-right">◉ Ludo no cut </span>

                                        </div>
                                        <div className="goverlay">
                                            <div className="text">Comming Soon</div>
                                        </div>
                                    </Link> */}

                {/* <Link className="gameCard-container" to={`/Homepage/Ludo%20Ulta`} > */}
                {/* <Link className="gameCard-container" to={``} >
                                        <span className="d-none blink text-danger d-block text-right">◉ LIVE</span>
                                        <picture className="gameCard-image">
                                            <img width="100%" src={process.env.PUBLIC_URL + '/Images/LandingPage_img/fourthLudo.webp'} alt="" />

                                        </picture>
                                        <div className="gameCard-title">
                                            <span className="d-none blink text-success d-block text-right">◉ Ludo Ulta </span>

                                        </div>

                                        <div className="goverlay">
                                            <div className="text">Comming Soon</div>
                                        </div>

                                    </Link> */}
              </div>
            </div>
          </section>
          <section className="footer">
            <div className="footer-divider" />
            <a
              className="px-3 py-4 d-flex align-items-center"
              href="#!"
              style={{ textDecoration: "none" }}
              onClick={() => setOpen(!open)}
              aria-controls="example-collapse-text"
              aria-expanded={open}
            >
              <picture className="icon">
                <img
                  src="/Images/LandingPage_img/logo.png"
                  width="56px"
                  height="56px"
                  alt="profile"
                  style={{ width: "125px", height: "40px" }}
                />
              </picture>
               <span
                style={{
                  color: "rgb(149, 149, 149)",
                  fontSize: "1em",
                  fontWeight: 400,
                }}
                className={!open ? "d-block" : "d-none"}
              >
                {" "}&nbsp; &nbsp; Terms, Privacy, Support
              </span>

              {open ? (
                <i
                  className="mdi mdi-chevron-up ml-auto"
                  style={{ fontSize: "1.7em", color: "rgb(103, 103, 103)" }}
                ></i>
              ) : (
                <i
                  style={{ fontSize: "1.7em", color: "rgb(103, 103, 103)" }}
                  className="mdi mdi-chevron-down ml-auto"
                ></i>
              )}
            </a>
            <Collapse in={open}>
              <div id="example-collapse-text" className="px-3 overflow-hidden">
                <div className="row footer-links">
                  <Link className="col-6" to="/term-condition">
                    Terms &amp; Condition
                  </Link>
                  <Link className="col-6" to="/PrivacyPolicy">
                    Privacy Policy
                  </Link>
                  <Link className="col-6" to="/RefundPolicy">
                    Refund/Cancellation Policy
                  </Link>
                  <Link className="col-6" to="/contact-us">
                    Contact Us
                  </Link>
                  <Link className="col-6" to="/responsible-gaming">
                    Responsible Gaming
                  </Link>
                </div>
              </div>
            </Collapse>
            <div className="footer-divider" />
            <div className="px-3 py-4">
              <div className="footer-text-bold">About Us</div>
              <br />
              <div className="footer-text">
                {WebSitesettings ? WebSitesettings.WebsiteName : ""} is a
                real-money gaming product owned and operated by{" "}
                {WebSitesettings ? WebSitesettings.CompanyName : ""} ("
                {WebSitesettings ? WebSitesettings.WebsiteName : ""}" or "We" or
                "Us" or "Our").
              </div>
              <br />
              <div className="footer-text-bold">
                Our Business &amp; Products
              </div>
              <br />
              <div className="footer-text">
                We are an HTML5 game-publishing company and our mission is to
                make accessing games fast and easy by removing the friction of
                app-installs.
              </div>
              <br />
              <div className="footer-text">
                {WebSitesettings ? WebSitesettings.WebsiteName : ""} is a
                skill-based real-money gaming platform accessible only for our
                users in India. It is accessible on{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={WebSitesettings ? WebSitesettings.CompanyWebsite : ""}
                >
                  {WebSitesettings ? WebSitesettings.CompanyWebsite : ""}
                </a>
                . On {WebSitesettings ? WebSitesettings.WebsiteName : ""}, users
                can compete for real cash in Tournaments and Battles. They can
                encash their winnings via popular options such as Paytm Wallet,
                Amazon Pay, Bank Transfer, Mobile Recharges etc.
              </div>
              <br />
              <div className="footer-text-bold">Our Games</div>
              <br />
              <div className="footer-text">
                {WebSitesettings ? WebSitesettings.WebsiteName : ""} has a
                wide-variety of high-quality, premium HTML5 games. Our games are
                especially compressed and optimised to work on low-end devices,
                uncommon browsers, and patchy internet speeds.
              </div>
              <br />
              <div className="footer-text">
                We have games across several popular categories: Arcade, Action,
                Adventure, Sports &amp; Racing, Strategy, Puzzle &amp; Logic. We
                also have a strong portfolio of multiplayer games such as Ludo,
                Chess, 8 Ball Pool, Carrom, Tic Tac Toe, Archery, Quiz, Chinese
                Checkers and more! Some of our popular titles are: Escape Run,
                Bubble Wipeout, Tower Twist, Cricket Gunda, Ludo With Friends.
                If you have any suggestions around new games that we should add
                or if you are a game developer yourself and want to work with
                us, don't hesitate to drop in a line at{" "}
                <a
                  href={
                    WebSitesettings
                      ? "mailto:" + WebSitesettings.CompanyEmail
                      : ""
                  }
                >
                  {WebSitesettings ? WebSitesettings.CompanyEmail : ""}
                </a>
                !
              </div>
            </div>
          </section>
          <div className="downloadButton">
            <Downloadbutton />
          </div>
        </div>
      </div>
      {/* // <div className='rightContainer'>
            //     <Rightcontainer/>
            // </div> */}
    </>
  );
}
